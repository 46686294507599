<template>
  <!-- NAME[epic=销售] 设置价格方案 -->
  <div class="orderTest-container">
    <el-form :model="form" inline label-width="90px">
      <!-- 商品搜索 -->
      <el-form-item>
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          :popper-class="'select-idx selectDC'"
          @select-goods-all="setGoodsInput"
        ></goods-search>
        <!-- <el-autocomplete
          v-model="form.goods_keyword"
          class="inline-input"
          :fetch-suggestions="querySearch"
          value-key="goods_name"
          placeholder="请输入商品名称、简拼"
          @select="handleSelect"
          @input="handleInput"
        ></el-autocomplete>
        <el-button
          icon="el-icon-search"
          circle
          class="icon-btn"
          @click="iconClick"
        ></el-button> -->
      </el-form-item>
      <!-- 客户搜索 -->
      <el-form-item>
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
        ></client-search>
      </el-form-item>

      <!-- 状态 -->
      <el-form-item prop="is_close">
        <el-select
          v-model="form.is_close"
          :popper-class="'select-idx'"
          placeholder="请选择状态"
          clearable
          style="width: 180px; margin-right: 5px"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button type="primary" @click="addPlan">添加方案</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table ref="table" v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(colItem, index) in finallyColumns"
        :key="index"
        :label="colItem.label"
        :prop="colItem.prop"
        :width="colItem.width"
        align="center"
      >
        <template v-if="colItem.label == '关联商品'" #default="{ row }">
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="goodsEdit(row)"
          >
            编辑
          </span>
        </template>
        <template v-else-if="colItem.label == '关联客户'" #default="{ row }">
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="clientEdit(row)"
          >
            编辑
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button type="text" @click.native.prevent="editRow($index, row)">
            修改
          </el-button>
          <el-button type="text" @click.native.prevent="copyRow($index, row)">
            复制
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleDeleteRow($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <create-edit ref="createEdit" @refresh="fetchData"></create-edit>
    <link-goods ref="linkGoods" show-app></link-goods>
    <link-client ref="linkClient"></link-client>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getIndexList, deleteRow } from '@/api/setPrice'
  import ClientSearch from '@/baseComponents/clientSearch'
  import CreateEdit from './components/createEdit'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import LinkGoods from './components/linkGoods'
  import LinkClient from './components/linkClient'
  export default {
    name: 'SetPrice',
    components: {
      ClientSearch,
      CreateEdit,
      GoodsSearch,
      LinkGoods,
      LinkClient,
    },
    data() {
      return {
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          pageNo: 1,
          pageSize: 10,
          goods_keyword: '',
          goods_id: '',
          is_close: '',
          cust_id: '',
        },
        // 状态下拉
        statusList: [
          {
            value: 0,
            label: '正常',
          },
          {
            value: 1,
            label: '禁用',
          },
        ],
        selectList: {},
        // 表格
        list: [],
        loading: true,
        checkList: [
          '方案名称',
          '开始有效时间',
          '结束有效时间',
          '状态',
          '创建时间',
          '优先级',
          '关联商品',
          '关联客户',
          '备注',
        ],
        columns: [
          {
            order: 1,
            prop: 'scheme_name',
            label: '方案名称',
            width: '110px',
          },
          {
            order: 2,
            prop: 'start_time_text',
            label: '开始有效时间',
            width: '110px',
          },
          {
            order: 2.5,
            prop: 'end_time_text',
            label: '结束有效时间',
            width: '110px',
          },
          {
            order: 3,
            prop: 'is_close_text',
            label: '状态',
            width: '110px',
          },
          {
            order: 4,
            prop: 'create_at',
            label: '创建时间',
            width: '110px',
          },
          {
            order: 5,
            prop: 'sort',
            label: '优先级',
            width: '110px',
          },
          {
            order: 6,
            prop: '',
            label: '关联商品',
            width: '110px',
          },
          {
            order: 7,
            prop: '',
            label: '关联客户',
            width: '110px',
          },
          {
            order: 8,
            prop: 'remark',
            label: '备注',
            width: '110px',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyList = []
        this.checkList.forEach((checkList) => {
          finallyList.push(
            this.columns.filter((item) => checkList === item.label)[0]
          )
        })
        return _.sortBy(finallyList, (item) => item.order)
      },
    },
    created() {
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      // 表格获取数据||刷新数据
      async fetchData(queryForm = {}) {
        let { data, code, msg, totalCount } = await getIndexList(this.form)

        console.log(data)
        if (code == 200) {
          this.list = data
          this.total = Number(totalCount)
        } else {
          this.$message.error(msg)
        }
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 客户搜索框返回值
      chooseRow(val) {
        console.log('kehuSearch')
        console.log(val)
        this.form.keyword = val
      },

      // 表格按钮事件
      editRow(index, row) {
        this.$refs['createEdit'].isEdit = 0
        this.$refs['createEdit'].id = row.id
        this.$refs['createEdit'].showDialog = true
      },
      copyRow(index, row) {},
      handleDeleteRow(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({
              id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      goodsEdit(row) {
        console.log('商品关联')
        console.log(row)
        this.$refs['linkGoods'].showDialog = true
        this.$refs['linkGoods'].id = row.id
      },
      clientEdit(row) {
        console.log('客户关联')
        console.log(row)
        this.$refs['linkClient'].id = row.id
        this.$refs['linkClient'].showDialog = true
      },
      // 筛选按钮
      handleQuery() {
        console.log(this.form)
        this.fetchData(this.form)
      },
      // 添加方案按钮
      addPlan() {
        this.$refs['createEdit'].isEdit = 1
        this.$refs['createEdit'].id = 0
        this.$refs['createEdit'].showDialog = true
      },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.form.cust_id = val
        } else {
          this.form.cust_id = val
        }
      },
      clearInput() {
        this.form.cust_id = ''
      },
      setGoodsInput(val) {
        this.form.goods_id = val.goods_id
      },
    },
  }
</script>
<style lang="scss" scoped></style>
