var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, inline: "", "label-width": "90px" } },
        [
          _c(
            "el-form-item",
            [
              _c("goods-search", {
                ref: "goodsSearch",
                attrs: {
                  "is-table": false,
                  "popper-class": "select-idx selectDC",
                },
                on: { "select-goods-all": _vm.setGoodsInput },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "select-idx selectDC" },
                on: { "select-id": _vm.selectId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_close" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px", "margin-right": "5px" },
                  attrs: {
                    "popper-class": "select-idx",
                    placeholder: "请选择状态",
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.is_close,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_close", $$v)
                    },
                    expression: "form.is_close",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPlan } },
                [_vm._v("添加方案")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _vm._l(_vm.finallyColumns, function (colItem, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                label: colItem.label,
                prop: colItem.prop,
                width: colItem.width,
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  colItem.label == "关联商品"
                    ? {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-decoration": "underline",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goodsEdit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ]
                        },
                      }
                    : colItem.label == "关联客户"
                    ? {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-decoration": "underline",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clientEdit(row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.editRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.copyRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 复制 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleDeleteRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("create-edit", { ref: "createEdit", on: { refresh: _vm.fetchData } }),
      _c("link-goods", { ref: "linkGoods", attrs: { "show-app": "" } }),
      _c("link-client", { ref: "linkClient" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }