<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      :title="title"
      width="700px"
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        inline
        label-width="110px"
        style="text-align: left"
      >
        <el-form-item prop="scheme_name" label="方案名称:" style="width: 100%">
          <el-input v-model="form.scheme_name"></el-input>
        </el-form-item>
        <el-form-item label="指定部门员工:" style="width: 100%">
          <el-button type="primary" @click="handleSet">设置</el-button>
        </el-form-item>
        <el-form-item prop="time" label="有效时间:">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="sort" label="优先级:" style="width: 100%">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item prop="remark" label="备注:" style="width: 100%">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item prop="is_close" label="状态:" style="width: 100%">
          <!-- is_close -->
          <el-radio v-model="form.is_close" :label="0">正常</el-radio>
          <el-radio v-model="form.is_close" :label="1">禁用</el-radio>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
    <set-user :id.sync="id" ref="setUser" @set-userid="getAllUserId"></set-user>
  </div>
</template>
<script>
  import { getDetail, saveData, createPlan } from '@/api/setPrice'
  import SetUser from './setComponent'
  export default {
    name: 'CreateEdit',
    components: {
      SetUser,
    },
    data() {
      return {
        loading: true,
        id: 0,
        isEdit: 0,
        showDialog: false,
        form: {
          time: ['', ''],
          user_ids: '',
          scheme_name: '',
          sort: '',
          remark: '',
          is_close: 0,
        },
        rules: {
          scheme_name: [
            { required: true, trigger: 'blur', message: '请输入方案名称' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
          time: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入有效时间',
            },
          ],
        },
      }
    },
    computed: {
      title() {
        if (this.isEdit == 0) {
          return '修改方案'
        } else if (this.isEdit == 1) {
          return '创建方案'
        }
        return 'xx方案'
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          if (this.isEdit == 0) {
            // 是修改
            this.fetchData()
          } else if (this.isEdit == 1) {
            // 是创建
            this.loading = false
          }
        } else {
          // 关闭窗口
          this.$refs.form.resetFields()
          this.loading = true
        }
      },
      'form.time'(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 如果是修改 获取数据
      async fetchData() {
        let { data, msg, code } = await getDetail({ id: this.id })
        console.log(data)
        // this.form = data
        Object.assign(this.form, data)
        console.log(this.form)
        let time = []
        time[0] = data.start_time_text
        time[1] = data.end_time_text
        this.form.time = time
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      handleSet() {
        this.$refs['setUser'].showDialog = true
      },
      getAllUserId(val) {
        this.form.user_ids = val
      },

      save() {
        console.log(this.form)
        this.$refs['form'].validate((go) => {
          if (go) {
            if (this.isEdit == 0) {
              saveData(this.form).then((res) => {
                if (res.code == 200) {
                  this.$message.success('保存成功')
                  this.$emit('refresh')
                  this.showDialog = false
                } else {
                  this.$message.error(res.msg)
                }
              })
            } else if (this.isEdit == 1) {
              createPlan(this.form).then((res) => {
                if (res.code == 200) {
                  this.$message.success('保存成功')
                  this.$refs['form'].resetFields()

                  this.$emit('refresh')
                  this.showDialog = false
                } else {
                  this.$message.error(res.msg)
                }
              })
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
