var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            title: _vm.title,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "form",
              staticStyle: { "text-align": "left" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                inline: "",
                "label-width": "110px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "scheme_name", label: "方案名称:" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.scheme_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "scheme_name", $$v)
                      },
                      expression: "form.scheme_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "指定部门员工:" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSet },
                    },
                    [_vm._v("设置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "time", label: "有效时间:" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "sort", label: "优先级:" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "remark", label: "备注:" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "is_close", label: "状态:" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.is_close,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_close", $$v)
                        },
                        expression: "form.is_close",
                      },
                    },
                    [_vm._v("正常")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.is_close,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_close", $$v)
                        },
                        expression: "form.is_close",
                      },
                    },
                    [_vm._v("禁用")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("set-user", {
        ref: "setUser",
        attrs: { id: _vm.id },
        on: {
          "update:id": function ($event) {
            _vm.id = $event
          },
          "set-userid": _vm.getAllUserId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }